var stickyHeader = true;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
var homeQuickGivingPreFooter = true;
var countUpStats = true;
var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// Slick slider for Timeline
$(".carouselImageMultiple").find('.carouselSlides').slick({
  slidesToShow: 1,
  centerMode: true,
  slidesToScroll: 1,
  arrows: false,
  dots: false,
  autoplay: false,
  autoplaySpeed: 6000,
  infinite: true,
  cssEase: 'linear',
  centerPadding: '0',
  mobileFirst: true,
  responsive: [{
    breakpoint: 768,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      centerPadding: '0',
      arrows: true
    }
  }
  ]
});

$(".homeBox1, .homeBox2, .homeBox3").prependTo(".homeFeaturesWrapper").wrapAll("<div class='homeFeatures homeFeaturesTop' />");



$(document).ready(function () {

  if ($(".homeFeedBox2").length) {
    $(".homeFeedBox2 .feedList").slick({
      mobileFirst: true,
      slidesToShow: 1,
      centerMode: true,
      arrows: false,
      infinite: true,
      responsive: [
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 1439,
          settings: {
            slidesToShow: 4,
            arrows: true,
            centerMode: false
          }
        },
      ]
    })

    $(".homeFeedBox1 .feedList").slick({
      mobileFirst: true,
      slidesToShow: 1,
      arrows: false,
      centerMode: true,
      responsive: [
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 1439,
          settings: {
            slidesToShow: 4,
            arrows: true
          }
        }
      ]
    })
  }

  if ($(".homeFeedBox3").length) {
    $(".homeFeedBox3 .feedList").slick({
      slidesToShow: 1,
      fade: true,
      dots: true,
      infinite: true,
      mobileFirst: true,
      adaptiveHeight: true
    })
  }

  //add button to each slide article 
  $(".homeFeedBox3 .feedItem").each(function () {
    const href = $(this).children("a").attr("href");
    const carouselDetail = $(this).find(".feedItemDetailsWrapper");

    $(carouselDetail).append(`<a href="${href}" class="cta-button pink">Read more</a>`)
  })
});

if ($(".Footer-Logos").length) {
  $(".Footer-Logos").slick({
    mobileFirst: true,
    dots: false,
    arrows: false,
    slidesToShow: 2,
    rows: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1439,
        settings: {
          slidesToShow: 5
        }
      }
    ]
  })
}

if ($("body.homepage").length) {
  //move sponsor a dog title before slider
  const sponsorADogTitle = $(".sponsor-a-dog-title")
  $(".homeHeadlinesIntro").append(sponsorADogTitle);

  // sponsorADog move button under slider
  const SADbutton = $(".sponsor-a-dog-title .cta-button");
  $(SADbutton).insertAfter(".SponsoradogFeed .feedList");

  // move the sponsor a dog feed into the .homeHeadlinesIntro section
  const homeFeedBox1 = $(".homeFeedBox1");
  $(".homeHeadlinesIntro").append(homeFeedBox1);

  // append homeFeedbox2 to HomeFeedIntro section
  const homeFeedBox2 = $(".homeFeed.homeFeedBox2");
  $(".homeFeedsIntro").append(homeFeedBox2);

  //move fundraising events button under slider
  const fundRaisingButton = $(".homeFeedsIntro .cta-button");
  $(fundRaisingButton).insertAfter(".homeFeedevents .feedList");

  // move homeFeedBox3 above impact stats section
  const homeFeedBox3 = $(".homeFeedBox3")
  $(".homeFeaturesWrapper").append(homeFeedBox3);

  const volunteerForUsTitle = $(".volunteer-for-us").siblings(".homeFeaturesTitle");
  if (volunteerForUsTitle.length) {
    $(".volunteer-for-us").insertBefore(".homeFeeds");
  }
}

//post pages header
if ($("article.post").length) {
  const header = $(".headerWrapper .headerText");
  $(header).insertBefore(".contentBlock");
}

//appeals page donate button
if ($(".appealPostPage").length) {
  const buttonWrapper = $(".appealActionsWrapper .headerText + .contentBlock");
  $(".postContent").prepend(buttonWrapper);
}

// Footer logos
$(".footerBox.Footer-Logos").insertAfter(".pageFooterWrapper");
$(".footerBox.Footer-copyright").insertAfter(".footerBox.Footer-Logos");

